import Service from '../Service'
import { API_URL } from '../../config'

const resource = `${API_URL}/products`

export default {

  getAllItems (itemsPerPage, page, pageNumber, filters) {
      const branchQuery = (filters && filters.branch_id && filters.branch_id !== '') ? '&branch_id=' + filters.branch_id : ''
      const categoryQuery = (filters && filters.category_id && filters.category_id !== '') ? '&category_id=' + filters.category_id : ''
      const labelQuery = (filters && filters.label && filters.label !== '') ? '&label=' + filters.label : ''
      const statusQuery = (filters && filters.status_id && filters.status_id !== '') ? '&status_id=' + filters.status_id : ''
      const metalTypeQuery = (filters && filters.metal_type && filters.metal_type !== '') ? '&metal_type=' + filters.metal_type : ''
      const filterQuery = branchQuery + categoryQuery + labelQuery + statusQuery + metalTypeQuery

        return Service.get(`${resource}?per_page=${itemsPerPage}&page=${page}${filterQuery}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            }
        })
    },
    exportItems (filters) {
      try {
      const response = Service.get(`${resource}/export-products`, {
        responseType: 'blob', // Ensure response type is set to 'blob'
      })
        return response
      } catch (error) {
        console.error('Error downloading file:', error)
      }
    },
    getStatus () {
      return Service.get(`${resource}/states`)
      .then((response) => {
          if (response.status === 200) {
              return response.data
          }
      })
  },
    getActivatedProducts (data) {
        return Service.get(`${resource}/active?label=${data}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            }
        })
    },
    getSoldProducts (data) {
      return Service.get(`${resource}/sold?label=${data}`)
      .then((response) => {
          if (response.status === 200) {
              return response.data
          }
      })
  },
    getProductById (id) {
      return Service.get(`${resource}/${id}`)
      .then((response) => {
          if (response.status === 200) {
              return response.data
          }
      })
  },
  updateData (data) {
    return Service.put(`${resource}/${data.id}`, data)
    .then((response) => {
        if (response.status === 200) {
            return response.data
        }
    })
},
AddNewItem (data) {
  return Service.post(`${resource}`, data)
  .then((response) => {
    console.log('response', response)
      if (response.status === 200) {
          return response.data
      }
  })
},
GetProductCode (id) {
  return Service.get(`${API_URL}/last-label?category_id=${id.id}`)
  .then((response) => {
    console.log('response', response)
      if (response.status === 200) {
          return response.data
      }
  })
},
}
