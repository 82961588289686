import Service from '../Service'
import { API_URL } from '../../config'

const resource = `${API_URL}`

export default {

  getAllItems (itemsPerPage, page, pageNumber, filters) {
    const labelQuery = (filters && filters.label && filters.label !== '') ? '&label=' + filters.label : ''
    const filterQuery = labelQuery
        return Service.get(`${resource}/transfer?per_page=${itemsPerPage}&page=${page}${filterQuery}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            }
        })
    },
    makeNewTransfer (branchId, productId) {
      console.log('branchId', branchId)
      console.log('productId', productId)
      return Service.put(`${resource}/branches/${branchId}/transfer`, productId)
        .then((response) => {
          console.log('response', response)
            if (response.status === 200) {
                return response.data
            }
        })
    },
}
