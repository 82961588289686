import Service from '../Service'
import { API_URL } from '../../config'

const resource = `${API_URL}/receipts`

export default {

  getAllItems (itemsPerPage, page, pageNumber, filters) {
const receiptQuery = (filters && filters.receipt_number && filters.receipt_number !== '') ? '&receipt_number=' + filters.receipt_number : ''
const fromQuery = (filters && filters.date_from && filters.date_from !== '') ? '&date_from=' + filters.date_from : ''
const clientQuery = (filters && filters.client_name && filters.client_name !== '') ? '&client_name=' + filters.client_name : ''
const toQuery = (filters && filters.date_to && filters.date_to !== '') ? '&date_to=' + filters.date_to : ''

const filterQuery = receiptQuery + fromQuery + clientQuery + toQuery
        return Service.get(`${resource}?per_page=${itemsPerPage}&page=${page}${filterQuery}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            }
        })
    },
    getAllCities () {
      return Service.get(`${API_URL}/cities/active`)
      .then((response) => {
          if (response.status === 200) {
              return response.data.data
          }
      })
  },
    fetchOneItem (id) {
      return Service.get(`${resource}/${id}`)
      .then((response) => {
        if (response.status === 200) {
            return response.data
        }
    })
    },
    getActiveList () {
      return Service.get(`${resource}/active`)
      .then((response) => {
        if (response.status === 200) {
            return response.data
        }
    })
    },
    AddNewItem (data) {
      return Service.post(`${resource}`, data)
      .then((response) => {
        console.log('add new response', response)
        if (response.status === 200) {
            return response.data
        }
    })
    },
    refund (data) {
      return Service.post(`${API_URL}/refund`, data)
      .then((response) => {
        console.log('add new response', response)
        if (response.status === 200) {
            return response.data
        }
    })
    },
    updateItem (id, data) {
      return Service.put(`${resource}/${id}`, data)
      .then((response) => {
        // console.log('update response', response)
        if (response.status === 200) {
            return response.data
        }
    })
    },
    deleteItem (id) {
      return Service.delete(`${resource}/${id}`)
      .then((response) => {
        if (response.status === 200) {
            return response.data
        }
    })
    },
}
