import Service from '../Service'
import { API_URL } from '../../config'

const resource = `${API_URL}/savebox`

export default {

  getAllItems (itemsPerPage, page, pageNumber, filters) {
    const branchQuery = (filters && filters.branch_id && filters.branch_id !== '') ? '&branch_id=' + filters.branch_id : ''
    const fromQuery = (filters && filters.from_date && filters.from_date !== '') ? '&from_date=' + filters.from_date : ''
    const clientQuery = (filters && filters.client_id && filters.client_id !== '') ? '&client_id=' + filters.client_id : ''
    const statusQuery = (filters && filters.status && filters.status !== '') ? '&status=' + filters.status : ''
    const toQuery = (filters && filters.to_date && filters.to_date !== '') ? '&to_date=' + filters.to_date : ''
    const paymentQuery = (filters && filters.payment_method && filters.payment_method !== '') ? '&payment_method=' + filters.payment_method : ''

    const filterQuery = branchQuery + fromQuery + clientQuery + statusQuery + toQuery + paymentQuery

        return Service.get(`${resource}/actions?per_page=${itemsPerPage}&page=${page}${filterQuery}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            }
        })
    },
    getAllSaveBox (itemsPerPage, page, pageNumber, filters) {
        const branchQuery = (filters && filters.branch_id && filters.branch_id !== '') ? '&branch_id=' + filters.branch_id : ''

        const filterQuery = branchQuery

            return Service.get(`${resource}/index?per_page=${itemsPerPage}&page=${page}${filterQuery}`)
            .then((response) => {
                console.log('response', response)
                if (response.status === 200) {
                    return response.data.data
                }
            })
    },
    makeTransfer (data) {
            return Service.post(`${resource}/transfer`, data)
            .then((response) => {
                console.log('response', response)
                if (response.status === 200) {
                    return response
                }
            })
    },
    addPayment (data) {
        return Service.post(`${resource}/add`, data)
        .then((response) => {
                if (response.status === 200) {
                    return response
                } else {
                    console.log('response', response)
                    return response
                }
        })
        .catch(error => {
            console.log('error', error.response.data.message)
            return error.response.data.message
        })
},
emptySaveBox (data) {
    return Service.post(`${resource}/empty`, data)
    .then((response) => {
            if (response.status === 200) {
                return response
            } else {
                console.log('response', response)
                return response
            }
    })
    .catch(error => {
        console.log('error', error.response.data.message)
        return error.response.data.message
    })
},
}
