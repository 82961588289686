import Service from '../Service'
import { API_URL } from '../../config'

const resource = `${API_URL}`

export default {

  fetchData () {
        return Service.get(`${resource}/settings`)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            }
        })
    },
    AddData (data) {
      return Service.post(`${resource}/settings/update`, data)
      .then((response) => {
        console.log('add new response', response)
        if (response.status === 200) {
            return response.data
        }
    })
    },
}
