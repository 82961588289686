import Service from '../Service'
import { API_URL } from '../../config'

const resource = `${API_URL}/products/certificates`

export default {

  getAllItems () {
        return Service.get(`${resource}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            }
        })
    },
}
