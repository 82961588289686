import Service from '../Service'
import { API_URL } from '../../config'
// import employees from '../../store/modules/employee';

const resource = `${API_URL}/sales`
const resource2 = `${API_URL}/products`

export default {

  getAllItems (itemsPerPage, page, pageNumber, filters) {
    const branchQuery = (filters && filters.branch_id && filters.branch_id !== '') ? '&branch_id=' + filters.branch_id : ''
    const receiptQuery = (filters && filters.receipt_number && filters.receipt_number !== '') ? '&receipt_number=' + filters.receipt_number : ''
    const fromDateQuery = (filters && filters.from_date && filters.from_date !== '') ? '&from_date=' + filters.from_date : ''
    const toDateQuery = (filters && filters.to_date && filters.to_date !== '') ? '&to_date=' + filters.to_date : ''
    const metalTypeQuery = (filters && filters.metal_type && filters.metal_type !== '') ? '&metal_type=' + filters.metal_type : ''
    const clienQuery = (filters && filters.client_id && filters.client_id !== '') ? '&client_id=' + filters.client_id : ''
    const employeQuery = (filters && filters.employee_id && filters.employee_id !== '') ? '&employee_id=' + filters.employee_id : ''
    const filterQuery = branchQuery + receiptQuery + fromDateQuery + toDateQuery + metalTypeQuery + clienQuery + employeQuery
        return Service.get(`${resource}?per_page=${itemsPerPage}&page=${page}${filterQuery}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            }
        })
    },
    exportItems () {
      try {
      const response = Service.get(`${resource2}/export-sales`, {
        responseType: 'blob', // Ensure response type is set to 'blob'
      })
        return response
      } catch (error) {
        console.error('Error downloading file:', error)
      }
    },
}
