
import categoryServices from './setting/categoryServices'
import branchesServices from './setting/branchesServices'
import stoneServices from './setting/stoneServices'
import employeeServices from './setting/employeeServices'
import invoicesServices from './invoices/invoicesServices'
import clientsServices from './clients/clientsServices'
import saveboxServices from './savebox/saveboxServices'
import salesServices from './sales/salesServices'
import mainStockServices from './mainStock/mainStockServices'
import optionServices from './options/optionServices'
import resellerServices from './reseller/Services'
import transferServices from './mainStock/transferServices'
import certificatesServices from './certificates/certificatesServices'

const services = {

  category: categoryServices,
  branches: branchesServices,
  stones: stoneServices,
  employee: employeeServices,
  invoices: invoicesServices,
  clients: clientsServices,
  savebox: saveboxServices,
  sales: salesServices,
  mainStock: mainStockServices,
  options: optionServices,
  reseller: resellerServices,
  transfer: transferServices,
  certificates: certificatesServices,
}

export const ServiceFactory = {
    get: name => services[name],
}
